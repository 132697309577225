import Navigation from '@/components/Navigation/Navigation';
import styles from './myStore.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useId, useMemo, useState } from 'react';
import classnames from 'classnames';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import Modal from '@/components/Modal/Modal';
import { useModalStore } from '@/stores/common/useModalStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { autoHypenTel } from '@/shared/lib/functions/auto-hypen-tel';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { INativeMessage, sendNativeMessage } from '@/shared/lib/functions/native-messge';
import SpinnerEffector from '@/components/Spinner/SpinnerEffector';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';

const MENU_LIST = {
  LOGIN_ACCOUNT: '로그인\n계정 관리',
  CALL_MANAGEMENT: '통화\n관리',
  RESERVATION: '예약\n관리',
  INQUIRY: '고객센터\n문의',
};

const MyStore = () => {
  const navigate = useNavigate();
  const { mall } = useMallStore();
  const { setAlertModal } = useModalStore();
  const { loginId, appPushContext } = useAuthStore();
  const [activeMenu, setActiveMenu] = useState(sessionStorage.getItem('activeMenu') ?? MENU_LIST.LOGIN_ACCOUNT);
  const logoutModal = useId();
  const [isLoading, setIsLoading] = useState(true);


  const logout = () => {
    setAlertModal({ visible: false, key: logoutModal });
    const message: INativeMessage = { action: 'request_logout' };
    sendNativeMessage(message);
  };
  useEffect(()=> {
    ChannelService.loadScript();
    ChannelService.boot({
      'pluginKey': 'bdb3ddaf-46db-4f48-a106-08a7c02d0d7e',
      'memberId': loginId,
      'profile': {
        'name': loginId,
      },
    }, function onBoot(error, user) {
      if (error) {
        console.error('boot error', error);
      }
    });
    return () => {
      ChannelService.shutdown();
    };
  }, []);

  // activeMenu 상태 저장
  useEffect(() => {
    sessionStorage.setItem('activeMenu', activeMenu ?? MENU_LIST.LOGIN_ACCOUNT);
  }, [activeMenu]);

  // 페이지 로드 시 activeMenu 상태 복원
  useEffect(() => {
    const savedMenu = sessionStorage.getItem('activeMenu');
    if (savedMenu) {
      setActiveMenu(savedMenu);
    }
  }, []);


  const switchMenuController = () =>
    useMemo(() => {
      switch (activeMenu) {
        case MENU_LIST.CALL_MANAGEMENT:
          return (
            <>
              {/* <li className={styles.link} onClick={() => navigate('/tablemanager/call-history')}>
                통화내역
              </li> */}
              <li className={styles.link} onClick={() => navigate('/tablemanager/call-alarm-setting')}>
                통화 알림 설정
              </li>
            </>
          );

        case MENU_LIST.RESERVATION:
          return (
            <>
              <li className={styles.link} onClick={() => navigate('/setting/schedule')}>
                예약 일정 관리
              </li>
              <li className={styles.link} onClick={() => navigate('/setting/table')}>
                테이블 설정
              </li>
              <li className={styles.link} onClick={() => navigate('/setting/policy')}>
                예약 정책 설정
              </li>
              {/* <li className={styles.link} onClick={() => navigate('/setting/policy/deposit')}>
                예약금 설정
              </li> */}
              <li className={styles.link} onClick={() => navigate('/setting/channel')}>
                온라인 예약 연동
              </li>
            </>
          );

        case MENU_LIST.INQUIRY:
          return (
            <>
              <li className={styles.link} onClick={()=> {
                ChannelService.showMessenger();
              }}>
                {/* <a href="mailto:help@tablemanager.io">1:1 문의</a> */}
               1:1 문의
              </li>
              <li className={styles.link} onClick={() => navigate('/tablemanager/my-store/clear-cache')}>
                캐시 삭제 방법
              </li>
            </>
          );

        default:
          return (
            <>
              <li className={styles.use_sub_title_wrapper}>
                <div className={styles.use_sub_title}>
                  로그인 정보<em>{loginId}</em>
                </div>
              </li>
              <li
                className={styles.link}
                onClick={() => {
                  setAlertModal({ visible: true, key: logoutModal });
                }}
              >
                로그아웃
              </li>
            </>
          );
      }
    }, [activeMenu]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (!mall && isLoading && !loginId) {
    return <SpinnerEffector loading />;
  }

  return (
    <div className={styles.my_store_all_wrapper}>
      <header className={styles.my_store__header}>내 매장</header>
      <main className={styles.my_store__container}>
        <aside className={styles.my_store__info}>
          <h2>{mall.mallName}</h2>
          <em>{autoHypenTel(mall.mallPhone)}</em>
        </aside>
        <section className={styles.sub_title__wrapper}>
          <h3>가게 관리</h3>
          <span>필수입력</span>
        </section>
        <article className={styles.navigation_menu__wrapper}>
          <div className={styles.navigation_menu__content}>
            <ul>
              <li className={classnames({ [styles.active_menu]: activeMenu === MENU_LIST.LOGIN_ACCOUNT })} onClick={() => setActiveMenu(MENU_LIST.LOGIN_ACCOUNT)}>
                {MENU_LIST.LOGIN_ACCOUNT}
              </li>
              <li className={classnames({ [styles.active_menu]: activeMenu === MENU_LIST.CALL_MANAGEMENT })} onClick={() => setActiveMenu(MENU_LIST.CALL_MANAGEMENT)}>
                {MENU_LIST.CALL_MANAGEMENT}
              </li>
              <li className={classnames({ [styles.active_menu]: activeMenu === MENU_LIST.RESERVATION })} onClick={() => setActiveMenu(MENU_LIST.RESERVATION)}>
                {MENU_LIST.RESERVATION}
              </li>
              <li className={classnames({ [styles.active_menu]: activeMenu === MENU_LIST.INQUIRY })} onClick={() => setActiveMenu(MENU_LIST.INQUIRY)}>
                {MENU_LIST.INQUIRY}
              </li>
            </ul>
          </div>
          <div className={classnames(styles.my_store__content, { [styles.activeFirstTab]: activeMenu === MENU_LIST.LOGIN_ACCOUNT })}>
            <ul>{switchMenuController()}</ul>
          </div>
        </article>
      </main>
      <Navigation />
      <ModalPortal>
        <Modal.Alert key={logoutModal} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">로그아웃 하시겠어요?</div>
          <div className="footer-multiple-buttons">
            <div onClick={() => setAlertModal({ visible: false, key: logoutModal })} className="footer default font__body_sm_sb">
              취소
            </div>
            <div className="footer success font__body_sm_sb" onClick={() => logout()}>
              로그아웃
            </div>
          </div>
        </Modal.Alert>
      </ModalPortal>
    </div>
  );
};

export default MyStore;
