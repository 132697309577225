import React, { useCallback, useEffect, useId, useRef, useState } from 'react';

import { ReactComponent as Call } from '@/assets/images/icons/call_full+bg.svg';
import { ICard } from './Card.type';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '../ModalPortal/ModalPortal';
import { TIME_FORMAT } from './Card.constants';
import Terces from '@/shared/apis/terces';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { reservationStateListKorean } from '@/shared/utils/common.type';
import styles from './Card.module.scss';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useMallStore } from '@/stores/common/useMallStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { instantDisabler } from '@/shared/lib/functions';
import { usePartnerStore } from '@/stores/common/usePartnerStore';

const MinutesToHHMM = (minute) => {
  const m = minute % 60;
  const h = (minute - m) / 60;
  return h.toString() + '시간 ' + (m < 10 ? '0' : '') + m.toString() + '분';
};

const Card = ({ reservation, cardIdx, className, isModal = false, onClose, onActivate }: ICard): React.ReactElement => {
  const navigate = useNavigate();
  const { accessToken } = useAuthStore();
  const { appName } = usePartnerStore();
  const [searchParams, setSearchParams] = useSearchParams();
  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });

  const { reservationMarkingColors, rooms: storeRooms, menus: storeMenus, reservationExtravalues: mallExtraValues } = useMallStore((state) => state.mall);
  const { setAlertModal, setToastModal, setMenuModal } = useModalStore();
  const [showColorSelect, setShowColorSelect] = useState(false);
  const [sendCancelAlarm, setSendCancelAlarm] = useState(false);

  const cardScrollableElement = useRef(null);

  const colorSetEnabled = true;
  const noShowEnabled = true;
  const showRevealTime = false;
  const {
    createdAt,
    reservationId,
    // reservation_code,
    // reservation_color,
    reservationMarkingColorId,
    userId,
    userName,
    userMemo,
    userPhone,
    userExtravalues,
    visitDate,
    visitTime,
    parties,
    rooms,
    userNickname,
    reservationName,
    reservationMemo,
    reservationRequests,
    menus,
    reservationState,
    reservationExtravalues,
    userPastValidReservationCount,
    isWalkIn,
    depositState,
    depositAmount,
    enteredAt,
    exitedAt,
    partnerName,
  } = reservation;

  const todayDate = new Date().toISOString().split('T')[0];
  const reservation_color = reservationMarkingColors.find(({ id }) => id === reservationMarkingColorId)?.color || null;
  const reservation_headcounts = parties.map(Object.values).filter(([_, cnt]) => cnt > 0);
  const reservation_table = storeRooms.filter((item) => rooms.find((subItem) => item.id === subItem.id));
  const reservation_menus = storeMenus.filter((item) => menus.find((subItem) => item.menuId === subItem.id));

  const cancleModalId = useId();
  const rollbackModalId = useId();
  const noShowModalId = useId();

  const userMemoId = useId();
  const reservationRequestsId = useId();

  const onSelectColor = useCallback(
    (e) => {
      const colorId = e.target.dataset.color;
      const originReservationMarkingColorId = reservation.reservationMarkingColorId;
      devServerApi.api.patch('/reservations/' + reservationId, Terces.encrypt({ reservationMarkingColorId: colorId })).catch(() => {
        reservation.reservationMarkingColorId = originReservationMarkingColorId;
      });
      reservation.reservationMarkingColorId = colorId;
      setShowColorSelect(false);
    },
    [reservation_color]
  );

  const onNoshow = () => {
    if (reservation.reservationState === 'noShow') return;
    setAlertModal({ visible: true, key: noShowModalId });
  };

  const setNoShow = () => {
    const originState = reservation.reservationState;
    devServerApi.api.patch('/reservations/' + reservationId, Terces.encrypt({ reservationState: 'noShow' })).catch(() => {
      reservation.reservationState = originState;
    });
    reservation.reservationState = 'noShow';
    setAlertModal({ visible: false, key: noShowModalId });
    onClose(null);
  };

  const onCancel = () => {
    if (reservation.reservationState === 'canceled') return;
    setAlertModal({ visible: true, key: cancleModalId });
  };

  const setCancel = () => {
    const originState = reservation.reservationState;
    devServerApi.api
      .patch(
        '/reservations/' + reservationId,
        Terces.encrypt({
          reservationState: 'cancel',
          cancelMessageTemplate: sendCancelAlarm,
          userId,
        })
      )
      .catch(() => {
        reservation.reservationState = originState;
      });
    reservation.reservationState = 'canceled';
    setAlertModal({ visible: false, key: cancleModalId });
    setToastModal({ key: 'reservationToastCancel', visible: true });
    onClose(null);
  };

  const onRollback = () => {
    if (reservation.reservationState !== 'canceled') return;
    setAlertModal({ visible: true, key: rollbackModalId });
  };

  const setRollback = () => {
    const originState = reservation.reservationState;
    devServerApi.api
      .patch(
        '/reservations/' + reservationId,
        Terces.encrypt({
          reservationState: 'valid',
          userId,
        })
      )
      .catch(() => {
        reservation.reservationState = originState;
      });
    reservation.reservationState = 'valid';
    setAlertModal({ visible: false, key: rollbackModalId });
    setToastModal({ key: 'reservationToastRollback', visible: true });
    onClose(null);
  };

  const onEnter = () => {
    if (reservation.reservationState === 'enter') return;
    const originState = reservation.reservationState;
    devServerApi.api.patch('/reservations/' + reservationId, Terces.encrypt({ reservationState: 'enter' })).catch(() => {
      reservation.reservationState = originState;
      reservation.enteredAt = null;
    });
    reservation.reservationState = 'enter';
    reservation.enteredAt = Date.now();
    onClose('enter');
  };

  const onExit = () => {
    if (reservation.reservationState === 'exit') return;
    const originState = reservation.reservationState;
    devServerApi.api.patch('/reservations/' + reservationId, Terces.encrypt({ reservationState: 'exit' })).catch(() => {
      reservation.reservationState = originState;
      reservation.exitedAt = null;
    });
    reservation.reservationState = 'exit';
    reservation.exitedAt = Date.now();
    onClose('exit');
  };

  const onRegister = () => {
    if (reservation.reservationState === 'valid') return;
    const originState = reservation.reservationState;
    devServerApi.api.patch('/reservations/' + reservationId, Terces.encrypt({ reservationState: 'valid' })).catch(() => {
      reservation.reservationState = originState;
    });
    reservation.reservationState = 'valid';
    onClose('valid');
  };

  const onClickDetail = () => {
    setMenuModal({ key: 'reservationsModal', visible: false });
    navigate('/reservation/detail/' + reservationId);
  };

  const handleOnCall = () => {
    document.location.href = `tel:${userPhone}`;
  };

  const formatted_reservation_time: string = dayjs(`${visitDate} ${Math.floor(visitTime / 60)}:${visitTime % 60}:00`).format(TIME_FORMAT);

  const isDetectScrollInfo = () => {
    const ele = cardScrollableElement.current;
    if (ele?.scrollTop > 0) {
      ele.style.borderTop = '1px solid #EDEDED';
    } else {
      ele.style.borderTop = null;
    }
  };

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    if (orderId) {
      onActivate && onActivate(cardIdx);
      onActivate && setMenuModal({ key: 'reservationsModal', visible: true });
      searchParams.delete('orderId');
      searchParams.delete('date');
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <>
      <div
        className={classnames(
          'card',
          styles.card,
          { [styles.card__spreaded]: true, [styles.card__active]: false, [styles.card__is_modal]: isModal },
          styles[reservationState],
          className
        )}
        onClick={() => {
          sessionStorage.setItem('date', reservation.visitDate as string);
          onActivate && onActivate(cardIdx);
          onActivate && setMenuModal({ key: 'reservationsModal', visible: true });
        }}
      >
        <div className={styles.card__time}>{formatted_reservation_time}</div>
        {reservationName ? <div className={styles.card__reservation_name}>{reservationName}</div> : null}
        <div className={styles.card__essential}>
          <span style={{ background: userName ? reservation_color : 'transparent' }}>
            {userName}
            <span className={styles.card__essential_phone}>{userPhone?.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')}</span>
          </span>
          {!onActivate && userPhone ? <Call className={classnames([styles.call_icon], { [styles.tm_call_icon]: appName === 'tablemanager' })} onClick={handleOnCall} /> : null}
        </div>
        <div className={styles.card__assign}>
          <div className={styles.card__assign_headcount}>
            {reservation_headcounts.length > 1
              ? reservation_headcounts.map((headcount) => <span key={headcount[0]}>{`${headcount[0]} ${headcount[1]}명`}</span>)
              : reservation_headcounts.map((headcount) => <span key={headcount[0]}>{`인원 ${headcount[1]}명`}</span>)}
          </div>
          <div className={styles.card__assign_table}>
            <span className={styles.card__assign_table_separator} />
            <span className={styles.card__assign_table_value}>
              {!reservation_table || reservation_table.length > 0 ? reservation_table.map((item) => item.name).join(', ') : '미지정'}
            </span>
          </div>
        </div>
        <section className={classnames({ [styles.card__reservation_info_scroll]: !onActivate })} onScroll={isDetectScrollInfo} ref={cardScrollableElement}>
          <ul className={styles.card__extrainfo}>
            {userNickname ? (
              <li>
                <div className={styles.card__extrainfo_label}>별칭</div>
                <div className={styles.card__extrainfo_value}>{userNickname}</div>
              </li>
            ) : null}
            {reservation_menus.length > 0 ? (
              <li>
                <div className={styles.card__extrainfo_label}>메뉴명</div>
                <div className={styles.card__extrainfo_value}>
                  {reservation_menus.map((menu) => `${menu.menuName}(${menus.find((item) => item.id === menu.menuId).count})`).join(', ')}
                </div>
              </li>
            ) : null}
            {!onActivate && reservationMemo ? (
              <li>
                <div className={styles.card__extrainfo_label}>예약메모</div>
                <div className={styles.card__extrainfo_value}>{reservationMemo}</div>
              </li>
            ) : null}
            {!onActivate && reservationRequests ? (
              <li>
                <div className={styles.card__extrainfo_label}>요청사항</div>
                <div className={styles.card__extrainfo_value}>{reservationRequests}</div>
              </li>
            ) : null}
            {!onActivate && userMemo ? (
              <li>
                <div className={styles.card__extrainfo_label}>고객메모</div>
                <div className={styles.card__extrainfo_value}>{userMemo}</div>
              </li>
            ) : null}
            {!onActivate && reservationExtravalues.length
              ? reservationExtravalues.map((reservationExtravalue, i) => {
                  let targetExtraValue = mallExtraValues.find((item) => item.id === reservationExtravalue.id);
                  if (reservationExtravalue.value.length) {
                    return (
                      <li key={reservationExtravalue.name + String(i)}>
                        <div className={styles.card__extrainfo_label}>{targetExtraValue.name}</div>
                        <div className={styles.card__extrainfo_value}>{reservationExtravalue.value}</div>
                      </li>
                    );
                  }
                  return null;
                })
              : null}
          </ul>
          <div className={styles.card__tag_container}>
            {userPastValidReservationCount < 1 ? <button className={classnames('tblm-tag-tag')}>첫방문</button> : null}
            <button className={classnames('tblm-tag-tag')}>{dayjs(createdAt).format('MM월DD일')}</button>
            <button className={classnames('tblm-tag-tag')}>{reservationStateListKorean[reservationState]}</button>
            {partnerName === '카카오' || partnerName === '네이버' ? (
              <button
                style={{ '--bdr': 'transparent', '--bg': partnerName === '카카오' ? '#F1CB05' : '#1EC800', '--txt': '#ffffff', '--fix': '#' } as React.CSSProperties}
                className={classnames('tblm-tag-color-user')}
              >
                #{partnerName}
              </button>
            ) : null}
            {partnerName && partnerName !== '카카오' && partnerName !== '네이버' ? (
              <button style={{ '--bdr': 'transparent', '--bg': '#1481DB', '--txt': '#ffffff', '--fix': '#' } as React.CSSProperties} className={classnames('tblm-tag-color-user')}>
                #{partnerName}
              </button>
            ) : null}
            {onActivate && reservationMemo ? (
              <button
                className={classnames('tblm-tag-tag', 'link')}
                onClick={(e) => {
                  e.stopPropagation();
                  setAlertModal({ visible: true, key: userMemoId });
                }}
              >
                예약메모
              </button>
            ) : null}
            {onActivate && reservationRequests ? (
              <button
                className={classnames('tblm-tag-tag', 'link')}
                onClick={(e) => {
                  e.stopPropagation();
                  setAlertModal({ visible: true, key: reservationRequestsId });
                }}
              >
                요청사항
              </button>
            ) : null}
            {depositState !== 'unused' ? <button className={classnames('tblm-tag-tag')}>{depositState === 'unpaid' ? '예약금대기' : '예약금확인'}</button> : null}

            {reservationExtravalues.map((reservationExtravalue, i) => {
              let targetExtraValue = mallExtraValues.find((item) => item.id === reservationExtravalue.id);
              if (targetExtraValue.hashtagDisplay !== 'none' && reservationExtravalue.value) {
                return (
                  <button
                    key={reservationExtravalue.name + String(i)}
                    className={classnames(reservationExtravalue.backgroundColor || reservationExtravalue.fontColor ? 'tblm-tag-color-user' : 'tblm-tag-tag')}
                    style={{ backgroundColor: reservationExtravalue.backgroundColor, color: reservationExtravalue.fontColor }}
                  >
                    {targetExtraValue.hashtagDisplay === 'value' ? reservationExtravalue.value : null}
                    {targetExtraValue.hashtagDisplay === 'name' ? targetExtraValue.name : null}
                  </button>
                );
              }
              return null;
            })}
            {userExtravalues.map((userExtravalue, i) =>
              userExtravalue.name ? (
                <button
                  key={userExtravalue.name + String(i)}
                  className={classnames(userExtravalue.backgroundColor || userExtravalue.fontColor ? 'tblm-tag-color-user' : 'tblm-tag-tag')}
                  style={{ backgroundColor: userExtravalue.backgroundColor, color: userExtravalue.fontColor }}
                >
                  {userExtravalue.name}
                </button>
              ) : null
            )}
            {depositState !== 'unused' ? <button className={classnames('tblm-tag-tag')}>{depositAmount.toLocaleString()}원</button> : null}
          </div>
        </section>
        {['valid', 'enter', 'exit'].includes(reservationState) && todayDate >= visitDate ? (
          <div className={styles.card__changestatus}>
            {/* {reservationState === 'valid' ? <button className={classnames({ [styles.card__status_active]: reservationState === 'valid' })}>접수</button> : null} */}
            <button className={classnames({ [styles.card__status_active]: reservationState === 'valid' })} onClick={instantDisabler(onRegister)}>
              접수
            </button>
            <button className={classnames({ [styles.card__status_active]: reservationState === 'enter' })} onClick={instantDisabler(onEnter)}>
              입장
            </button>
            <button className={classnames({ [styles.card__status_active]: reservationState === 'exit' })} onClick={instantDisabler(onExit)}>
              퇴장
            </button>
          </div>
        ) : null}
        <hr className={styles.card__separator} />
        <div className={styles.card__controls}>
          {colorSetEnabled && reservationState !== 'canceled' && reservationState !== 'noShow' ? (
            <>
              <button className={styles.card__control_color} style={{ '--color': reservation_color } as React.CSSProperties} onClick={() => setShowColorSelect(!showColorSelect)} />
              <div className={classnames(styles['card__color-selector'], { showColorSelect })} onClick={instantDisabler(onSelectColor)}>
                <button className={styles['card__color-selector_color_none']} data-color={'no'} />
                {reservationMarkingColors.map(({ id, color }) => (
                  <button key={id} className={styles['card__color-selector_color']} data-color={id} style={{ '--color': color } as React.CSSProperties} />
                ))}
              </div>
            </>
          ) : null}
          {reservationState !== 'noShow' && reservationState === 'valid' && noShowEnabled && !showRevealTime && todayDate >= visitDate ? (
            <button className={styles.card__control_noshow} onClick={instantDisabler(onNoshow)}>
              No Show
            </button>
          ) : null}
          {reservationState !== 'noShow' && reservationState === 'valid' ? (
            <button className={styles.card__control_cancel} onClick={instantDisabler(onCancel)}>
              예약취소
            </button>
          ) : null}
          {reservationState !== 'noShow' && (reservationState === 'enter' || reservationState === 'exit') ? (
            <div className={`${styles.card__control_revealtime} font__subtitle_sb`}>
              {reservationState === 'enter' && enteredAt
                ? `${dayjs(enteredAt).format('HH:mm')} ~ 현재 (${MinutesToHHMM(dayjs(Date.now()).diff(dayjs(enteredAt), 'minute') + 1)})`
                : null}
              {reservationState === 'exit' && exitedAt && enteredAt
                ? `${dayjs(enteredAt).format('HH:mm')} ~ ${dayjs(exitedAt).format('HH:mm')} (${MinutesToHHMM(dayjs(exitedAt).diff(dayjs(enteredAt), 'minute') + 1)})`
                : null}
              {reservationState === 'exit' && exitedAt && !enteredAt ? `퇴장시간 ${dayjs(exitedAt).format('HH:mm')}` : null}
            </div>
          ) : null}
          {reservationState === 'canceled' ? (
            <button className={styles.card__control_cancel} onClick={instantDisabler(onRollback)}>
              예약 복구
            </button>
          ) : null}
          {!isWalkIn ? (
            <button className={styles.card__control_detail} onClick={onClickDetail}>
              예약 상세{reservationState === 'canceled' ? '' : '/수정'}
            </button>
          ) : null}
        </div>
      </div>
      <ModalPortal>
        <Modal.Alert key={noShowModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">본 예약을 미방문 예약으로 분류하시겠습니까?</div>
          <div className="content font__body_sm">
            미방문 예약으로 분류 시 해당 고객을 No Show 고객으로 분류합니다. 사전에 예약을 취소한 경우에는 예약취소를 이용해주시기 바랍니다.
          </div>
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(setNoShow)}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Alert key={cancleModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">예약을 정말로 취소하시겠습니까?</div>
          <div className="content font__body_sm">
            예약 취소 시 취소 확인 문자가 발송됩니다. 취소한 예약은 복구할 수 있습니다.
            <div className="send_cancel_alarm_wrap">
              <label className="tblm-rc">
                <input type="checkbox" name="send_cancel_alarm" checked={sendCancelAlarm} onChange={() => setSendCancelAlarm((state) => !state)} />
                <i />
                <span>취소 문자 발송</span>
              </label>
            </div>
          </div>
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(setCancel)}>
            예약 취소
          </div>
        </Modal.Alert>
        <Modal.Alert key={rollbackModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">예약을 복구하시겠어요?</div>
          <div className="content font__body_sm">
            {/* 예약금 설정이 켜져 있을 때 */}
            {depositState !== 'unused' ? (
              <div className="send_cancel_alarm_wrap receive-deposit-check-wrap">
                <label className="tblm-rc">
                  <input type="checkbox" name="send_cancel_alarm" checked={sendCancelAlarm} onChange={() => setSendCancelAlarm((state) => !state)} />
                  <i />
                  <span>예약금을 받습니다.</span>
                </label>
              </div>
            ) : null}
          </div>
          <div className="footer success font__body_sm_sb" onClick={instantDisabler(setRollback)}>
            예약복구
          </div>
        </Modal.Alert>
        <Modal.Alert key={userMemoId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">예약메모</div>
          <div className="content font__body_sm">{reservationMemo}</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: userMemoId })}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Alert key={reservationRequestsId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">요청사항</div>
          <div className="content font__body_sm">{reservationRequests}</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: reservationRequestsId })}>
            확인
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Card;
