import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useId, useMemo, useRef } from 'react';

import CheckReservation from '@/components/Reservation/Accept/CheckReservation/CheckReservation';
import EtcInfo from '@/components/Reservation/Accept/EtcInfo/EtcInfo';
import FooterSub from '@/components/FooterSub/FooterSub';
import HeaderSub from '@/components/HeaderSub/HeaderSub';
import MenuInfo from '@/components/Reservation/Accept/MenuInfo/MenuInfo';
import MessageTemplateInfo from '@/components/Reservation/Accept/MessageTemplateInfo/MessageTemplateInfo';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import ReservationInfo from '@/components/Reservation/Accept/ReservationInfo/ReservationInfo';
import UserInfo from '@/components/Reservation/Accept/UserInfo/UserInfo';
import { devServerApi } from '@/shared/apis/devServerApi';
import { reservationSchema } from '@/hook-form/schema/reservation';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useLayoutStore } from '@/stores/common/useLayoutStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useReservationStore } from '@/stores/reservation/useReservationStore';
import { yupResolver } from '@hookform/resolvers/yup';
import Terces from '@/shared/apis/terces';
import { instantDisabler } from '@/shared/lib/functions';
import DepositSetting from '@/components/Reservation/Accept/DepositSetting/DepositSetting';

const INITIAL_STEP_TEXTS = {
  header: (
    <>
      예약 정보 확인 및 <br />
      상세 정보를 입력 해주세요
    </>
  ),
  footer: { back: '뒤로', success: '예약완료' },
};

const Step5 = (): React.ReactElement => {
  const keyboard = useLayoutStore((s) => s.keyboard);
  const { visitDate, visitTime, parties, rooms, menus, reservationInfo, userInfo, setReservationUpdateStore, setReservationResetStore, depositInfo } = useReservationStore();

  const { accessToken } = useAuthStore();
  const { setAlertModal } = useModalStore();

  devServerApi.configure({ headers: { 'x-tm-apigw-token': accessToken } });

  const navigate = useNavigate();
  const requiredModalId = useId();
  const closeConfirmModalId = useId();

  const validationSchema = useMemo(() => reservationSchema, []);
  const methods = useForm<any>({
    defaultValues: {
      visitDate,
      visitTime,
      parties,
      rooms,
      ...reservationInfo,
      ...userInfo,
      alarmMessageTemplate: userInfo?.alarmMessageTemplate,
      creationMessageTemplate: userInfo?.creationMessageTemplate,
      ...depositInfo,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const { handleSubmit, getValues } = methods;

  const handleMoveRoute = (path) => {
    setReservationUpdateStore({ ...getValues(), menus });
    navigate(path);
  };

  const handleOnClose = () => {
    setAlertModal({ visible: true, key: closeConfirmModalId });
  };

  const handleOnSubmit = (data) => {
    const result = {
      ...data,
      visitDate: data.visitDate.format('YYYY-MM-DD'),
      visitTime: Number(data.visitTime.hour() * 60 + data.visitTime.minute()),
    };

    if (!result.alarmMessageTemplate) delete result.alarmMessageTemplate;
    if (!result.creationMessageTemplate) delete result.creationMessageTemplate;

    result.reservationExtravalues = result.reservationExtravalues.filter((item) => item.value);
    result.reservationExtravalues = result.reservationExtravalues.map((item) => ({ id: item.id, name: item.name, value: item.value }));

    result.userExtravalues = result.userExtravalues.filter((item) => item.value);
    result.userExtravalues = result.userExtravalues.map((item) => ({ id: item.id, name: item.name, value: item.value }));

    result.menus = menus;
    devServerApi.api
      .post('/reservations', Terces.encrypt(result))
      .then(() => {
        setReservationResetStore();
        navigate(`/reservation/list?date=${result.visitDate}`);
      })
      .catch((e) => console.log(e));
  };

  const handleOnError = (errors) => {
    const refName = errors[Object.keys(errors)[0]].ref?.name;
    if (refName) {
      document.getElementsByName(refName)[0].scrollIntoView();
      window.scrollTo(0, window.scrollY - 76);
    } else {
      window.scrollTo(0, 0);
    }
    setAlertModal({ visible: true, key: requiredModalId });
  };

  return (
    <>
      <FormProvider {...methods}>
        <HeaderSub onClose={handleOnClose} isScrollTitleVisible>
          {INITIAL_STEP_TEXTS.header}
        </HeaderSub>
        <section className="reservation-check-wrapper">
          <CheckReservation handleMoveRoute={handleMoveRoute} />
        </section>

        <section className="reservation-detail-wrapper">
          <div className="font__body_md pb-4">메시지 설정</div>
          <MessageTemplateInfo />

          <div className="font__body_md pt-16 pb-4">
            예약 상세정보 입력<span className="guide-message">(추후 입력/수정 가능)</span>
          </div>
          <ReservationInfo />
          <UserInfo />
          <MenuInfo />
          <EtcInfo />
          {/* <DepositSetting handleMoveRoute={navigate} /> */}
        </section>

        <FooterSub isBackArea={!keyboard} isVisible isBorder style={keyboard ? { display: 'none' } : {}}>
          <div className="flex-container gap-8 w-full">
            <Link to="/reservation/accept/step-4/" className="w-full">
              <button className="footer tblm-button-normal w-full">{INITIAL_STEP_TEXTS.footer.back}</button>
            </Link>
            <button onClick={instantDisabler(handleSubmit(handleOnSubmit, handleOnError))} className="footer tblm-button-normal w-full tblm-btn-primary">
              {INITIAL_STEP_TEXTS.footer.success}
            </button>
          </div>
        </FooterSub>
      </FormProvider>
      <ModalPortal>
        <Modal.Alert key={requiredModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">예약 필수 정보를 입력해주세요.</div>
          <div className="footer success font__body_sm_sb" onClick={() => setAlertModal({ visible: false, key: requiredModalId })}>
            확인
          </div>
        </Modal.Alert>
        <Modal.Alert key={closeConfirmModalId} modal="alert" isDim={true} isAnimation={true}>
          <div className="title font__body_md_sb">접수를 취소하시겠습니까?</div>
          <div className="content font__body_sm">현재 작성중인 접수를 취소하면 복구가 불가능합니다.</div>
          <div
            className="footer success font__body_sm_sb"
            onClick={() => {
              setAlertModal({ visible: false, key: closeConfirmModalId });
              setReservationResetStore();
              navigate('/reservation/list');
            }}
          >
            접수 취소
          </div>
        </Modal.Alert>
      </ModalPortal>
    </>
  );
};

export default Step5;
