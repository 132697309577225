import React, { useNavigate } from 'react-router-dom';
import styles from './Navigation.module.scss';
import { ReactComponent as CallHistory } from '@/assets/images/icons/Nav_Call_History.svg';
import { ReactComponent as Reservation } from '@/assets/images/icons/Nav_Reservation.svg';
import { ReactComponent as MyStore } from '@/assets/images/icons/Nav_Store.svg';
import classNames from 'classnames';
import { useLayoutEffect, useState } from 'react';

// NAV_PATH.CALL_HISTORY, NAV_PATH.STORE pathname 확정되면 변경 되어야 함 (현재 임시 값)

const NAV_TYPE = {
  RESERVATION: 'reservation',
  CALL_HISTORY: 'call_history',
  STORE: 'store',
};

const NAV_PATH = {
  RESERVATION: '/reservation/list',
  CALL_HISTORY: '/tablemanager/call-history',
  STORE: '/tablemanager/my-store',
};

const MENU_LIST = {
  LOGIN_ACCOUNT: '로그인\n계정 관리',
  CALL_MANAGEMENT: '통화\n관리',
  RESERVATION: '예약\n관리',
  INQUIRY: '고객센터\n문의',
};

const Navigation = () => {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState(null);

  const goToLink = (linkUri: string) => {
    switch (linkUri) {
      case NAV_TYPE.RESERVATION:
        navigate(NAV_PATH.RESERVATION);
        setActiveNav(NAV_PATH.RESERVATION);
        break;
      case NAV_TYPE.CALL_HISTORY:
        navigate(NAV_PATH.CALL_HISTORY);
        setActiveNav(NAV_PATH.CALL_HISTORY);
        break;
      case NAV_TYPE.STORE:
        navigate(NAV_PATH.STORE);
        setActiveNav(NAV_PATH.STORE);
        sessionStorage.setItem('activeMenu', MENU_LIST.LOGIN_ACCOUNT);
        break;
    }
  };

  useLayoutEffect(() => {
    if (window.location.pathname.includes(NAV_PATH.RESERVATION)) {
      setActiveNav(NAV_TYPE.RESERVATION);
    }
    if (window.location.pathname.includes(NAV_PATH.CALL_HISTORY)) {
      setActiveNav(NAV_TYPE.CALL_HISTORY);
    }
    if (window.location.pathname.includes(NAV_PATH.STORE)) {
      setActiveNav(NAV_TYPE.STORE);
    }
  }, [activeNav]);

  return (
    <nav className={styles.navigation__container}>
      <ul className={styles.navigation__list}>
        <li onClick={() => goToLink(NAV_TYPE.RESERVATION)} className={classNames({ [styles.active_btn]: activeNav === NAV_TYPE.RESERVATION })}>
          <span className={styles.nav__icon}>
            <Reservation />
          </span>
          <div>예약</div>
        </li>
        {/* <li onClick={() => goToLink(NAV_TYPE.CALL_HISTORY)} className={classNames({ [styles.active_btn]: activeNav === NAV_TYPE.CALL_HISTORY })}>
          <span className={styles.nav__icon}>
            <CallHistory />
          </span>
          <div>통화내역</div>
        </li> */}
        <li onClick={() => goToLink(NAV_TYPE.STORE)} className={classNames({ [styles.active_btn]: activeNav === NAV_TYPE.STORE })}>
          <span className={styles.nav__icon}>
            <MyStore />
          </span>
          <div>내 매장</div>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
