import './ReservationListCalendar.scss';

import React, { useState } from 'react';

import HeaderSub from '@/components/HeaderSub/HeaderSub';
import MonthCalendar from '@/components/MonthCalendar/MonthCalendar';
import QUERY_KEYS from '@/shared/apis/queryKeys/common';
import SpinnerEffector from '../Spinner/SpinnerEffector';
import dayjs from 'dayjs';
import { devServerApi } from '@/shared/apis/devServerApi';
import { useAuthStore } from '@/stores/common/useAuthStore';
import { useQuery } from 'react-query';
import { useReservationCalendarStore } from '@/stores/reservation/useReservationCalendarStore';

const ReservationListCalendar = ({ visitDate, setVisitDate, onClose }): React.ReactElement => {
  const { accessToken } = useAuthStore();
  const { reservationList } = useReservationCalendarStore();
  const [eventDays, setEventDays] = useState([]);

  const today = dayjs();
  const { isFetching } = useQuery([QUERY_KEYS.GET_EVENT_DAY], () => devServerApi.authGet('/eventDays', accessToken), {
    onSuccess: (res: any) => {
      setEventDays(res.eventdays?.map((item) => ({ date: dayjs(`${item.year}-${item.month}-${item.day}`), memo: '테스트 휴무' })));
    },
  });

  if (isFetching) return <SpinnerEffector loading />;
  return (
    <>
      <HeaderSub onClose={onClose} isBorder={false}>
        월간 예약 현황
      </HeaderSub>
      <section className="month-calendar-wrapper">
        <MonthCalendar
          select={(target) => {
            setVisitDate(target);
            onClose();
          }}
          startDate={today.add(-1, 'month')}
          targetDate={today}
          selectedDate={visitDate}
          disabledBeforeDay={today}
          orderList
          period={5}
          holidays={eventDays}
          headerHeight={55}
          reservationList={reservationList}
        />
      </section>
    </>
  );
};

export default ReservationListCalendar;
